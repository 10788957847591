// 
// custom.scss
//

.wrapper {
    overflow: hidden;
}

header {
    transition: transform .5s ease;

    &.nav-up {
        @include media-breakpoint-up(lg) { 
            transform: translateY(-70px);
        }
    }

    .dropdown-toggle {
        // font-weight: 600;

        i {
            font-size: 17px;
            transform: translateY(1px);
        }
    }

    .dropdown-menu {
        span {
            // font-weight: 600;
        }

        i {
            font-size: 16px;
            transform: translateY(-1px);
            display: inline-block;
        }

        .dropdown-divider {
            margin-right: -0.3rem;
            margin-left: -0.3rem;
        }
    }

    .has-submenu {
        a {
            transition: none;
            // font-weight: 500;
            
            &:hover {
                color: $custom !important;
            }

            i {
                font-size: 17px;
                transform: translateY(1px);
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}

.modal-content {
    border-radius: 8px;
}

.swal2-modal {
    .swal2-title {
        font-size: 22px;
        line-height: 1.5;
        margin: .6em 0;
    }

    .swal2-actions {
        button {
            padding: 8px 30px;
            font-size: 16px !important;
            font-weight: 600;
        }
    }
}
    
.page-title {
    a {
        color: inherit;
    }
}

.header-title {
    font-size: 1.2rem;
    margin: 0 0 10px 0;
}

.label-heading {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    letter-spacing: .5px;
}

.label-heading-xs {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0 0 10px 0;
    width: 100%;
    color: $gray-600;
    letter-spacing: .75px;
    line-height: 1.4;
}

.font-600 {
    font-weight: 600 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-nunito {
    font-family: Nunito, sans-serif;
}

.font-cerebri {
    font-family: "Cerebri Sans,sans-serif";
}

.btn {
    border-radius: 6px;
    font-weight: 600;

    &:focus {
        box-shadow: none !important;
    }

    i {
        font-size: 16px;
        transform: translateY(1px);
        display: inline-block;
    }
}

.btn-link {
    cursor: pointer;
    font-weight: 600;
}

.btn-square {
    padding: 0;
    height: 37px;
    width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        margin-top: -4px;
    }
}

.btn-circle {
    padding: 0;
    height: 37px;
    width: 37px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        margin-top: -4px;
    }
}

.btn-final {
    padding: 10px 17px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .75px;
    font-family: "Cerebri Sans,sans-serif";
}

.btn-md-block {
    width: 100%;

    @include media-breakpoint-down(sm) { 
        width: auto;
    }
}
.side-menu {
    .badge {
        border-radius: 50px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: inherit;
    }
}


body {
    padding-bottom: 100px;

    @include media-breakpoint-down(md) { 
        padding-bottom: 200px;
    }

    &.authentication-bg-pattern {
        background-color: #f5f6f8;
    }
}

.font-weight-semi-bold {
    font-weight: 600;
}

.card {
    overflow: hidden;
    border-radius: 7px;
}

.card-box {
    border-radius: 7px;
    padding: 24px 26px;
}

input {
    border-radius: 4px !important;
}

.account-pages {
    .card {
        box-shadow: 0 0.75rem 6rem rgba(56,65,74,.08);
        border-radius: 8px;
        overflow: hidden;
    }

    .email-success-icon {
        font-size: 70px;
    }

    hr {
        border-top: 1px solid #eaf2f5;
        margin-left: -3rem;
        margin-right: -3rem;
    }
}

.card.bg-pattern {
    background-size: 500px 332px;
    background-repeat: no-repeat;
}

#topnav {
    .navbar-toggle {
        .lines {
            margin: 15px 15px 0 15px;
        }
    } 
}

.topbar-dark {
    #topnav {
        background-color: #1b1b1b !important;
    }

    .navbar-custom {
        background-color: #212121 !important;
        box-shadow: none;
    }
}

#profile-dropdown {
    width: 195px;
}

.side-menu {
    .nav-link {
        display: flex;
        align-items: center;
        flex-grow: 1;
        border-radius: 7px;
        width: 200px;
        background-color: rgba(255,255,255,.75);
        margin: 0 8px 8px 0;
        box-shadow: 0 0.75rem 6rem rgba(56,65,74,.03);

        @include media-breakpoint-up(md) { 
            margin: 0px;
            background-color: transparent;
        }

        &.active {
            background-color: $custom;
        }

        &:hover {
            &:not(.active) {
                color: $custom;
            }
        }

        i {
            font-size: 18px;
            margin-right: 8px;
        }
    }

    .nav-pills > a {
        font-weight: bold;
    }
}

.page-title-box {
    .page-title {
        line-height: normal;
        margin: 30px 0;
        white-space: normal;
        font-size: 24px;
    }
}

.form-control-plaintext {
    color: $gray-600;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    padding: .45rem .9rem;
    cursor: not-allowed;
    background-color: #f7f7f9;
}

.full-divider {
    margin-left: -30px;
    margin-right: -30px;
    border-top-color: #f1eeee;
}

.linear-accordion {
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;

    .card {
        border: 1px solid #f1eeee;
        margin-bottom: 20px;
        box-shadow: none;
    }

    .card-header {
        background-color: #f5f6f8;
        transition: all .5s;

        .label {
            text-transform: uppercase;
            float: right;
            color: $gray-500;
            font-size: 12px;
            letter-spacing: .5px;
            margin-top: 2px;
            font-weight: 600;

            @include media-breakpoint-down(sm) { 
                display: none;
            }
        }

        i {
            opacity: inherit;

            &.fe-check {
                display: none;
            }
        }

        &.disabled {
            cursor: not-allowed;

            h4 {
                div {
                    color: $gray-600 !important;
                }
            }

            i {
                color: $gray-600 !important;
            }

            a {
                pointer-events: none;
                color: inherit;
                text-decoration: none;
                display: block;
            }
        }

        &.active {
            a {
                pointer-events: none;
                color: inherit;
                text-decoration: none;
                display: block;
            }
        }

        &.done {
            h4 {
                div {
                    color: $success !important;
                }
            }

            i {
                display: none;

                &.fe-check {
                    display: inline;
                }
            }
        }
    }

    .previous-step {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        justify-content: center;

        i {
            @include media-breakpoint-up(sm) { 
                margin-bottom: 3px;
            }
        }
    }

    .card-label {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .5px;
    }

    .label-highlight {
        font-size: 20px;
        font-weight: 700;
        color: $gray-900;
    }

    .label-highlight-cerebri {
        font-size: 18px;
        font-weight: 600;
        color: $primary;
        font-family: "Cerebri Sans,sans-serif";
    }
}

.text-18 {
    font-size: 18px;
}

.mt-xs {
    margin-top: 3px;
}

///////////  NASTENKA  ///////////

#nastenka {
    .oslovenie {
        display: none;

        &.active {
            display: block;
        }
    }

    .circle-button-block {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: 110px;
        font-family: Nunito, sans-serif;

        a {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: 600;
        }

        .btn {
            margin-bottom: 8px;
        }
    }

    .nastenka-heading-lg {
        font-size: 26px !important;
    }

    .nastenka-heading {
        font-size: 22px !important;
    }
}

#nastenkna_uvod {
    .btn {
        height: 37px;
    }
}

.wave-corner-bg {
    position: absolute;
    top: 0;
    right: 0;

    @include media-breakpoint-down(xs) { 
        top: inherit;
        bottom: 0;
        transform: rotate(90deg);
    }
}

#rozvrh_nastenka {
    .month-wrapper {
        background: #fafafa !important;
        border: 1px solid #f1eeee;
        border-radius: 7px;
    }

    .week-wrapper {
        margin-right: 15px;
    }

    .week-header {
        border: 1px solid #f1eeee;
        box-shadow: none !important;
    }

    .week-number {
        width: 180px !important;
    }

    .micro-time {
        @include media-breakpoint-down(md) { 
            font-size: 8px !important;
        }
    }
    .room {
        margin-left: 15px;

        @media (min-width: 900px) { 
            display: block !important;
        }

        @include media-breakpoint-down(sm) { 
            display: none !important;
        }
    }

    .desktop-timetable {
        border: 1px solid #f1eeee;
        border-radius: 7px;
    }

    .btn {
        font-family: Nunito, sans-serif;
    }
}

#kurzy_nastenka {
    .card {
        border: 1px solid #f1eeee;
        margin-bottom: 0px !important;

        @include media-breakpoint-down(md) { 
            margin-bottom: 30px !important;
        }
    }
}

///////////  ROZVRH  ///////////

.semi-transparent-card {
    padding: 15px 20px;
    border-radius: 8px;
    background: -webkit-gradient(linear,left top,right top,color-stop(0,#fdfdfd),color-stop(50%,rgba(250,250,250,0)),to(rgba(250,250,250,0)));
    background: linear-gradient(90deg,#fdfdfd 0,rgba(250,250,250,0) 50%,rgba(250,250,250,0) 100%);
}

#legenda {
    font-family: "Cerebri Sans,sans-serif";
    padding: 40px 10px 0px 20px;

    @include media-breakpoint-down(sm) { 
        display: none;
    }

    h5 {
        text-transform: uppercase;
        letter-spacing: .25px;
        font-weight: 600;
        font-size: 11px;
        width: 60px;
        display: inline-block;
        color: $gray-700;
        margin: 7px 0 7px 0;
    }

    .item {
        margin-right: 30px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: $gray-700;
        margin-bottom: 2px;
        font-size: 11px;

        .item-pill {
            display: inline-block;
            padding: 3px 8px;
            border-radius: 15px;
            box-sizing: border-box;
            font-weight: 600;
            letter-spacing: .5px;
            text-transform: uppercase;
            font-size: 9px;
            margin-right: 7.5px;
            line-height: initial;
            border: 1px solid transparent;
        }
    }
}

#rozvrh {
    font-family: "Cerebri Sans,sans-serif";

    .month-wrapper {
        padding: 15px 0px 5px 15px;
        background: linear-gradient(90deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 50%, rgba(250, 250, 250, 0) 100%);
        border-radius: 7px;

        .card {
            padding: 10px 15px;
            flex-direction: row;
            margin-bottom: 0;
        }
    }

    .week-wrapper {
        margin-top: 75px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .week-header {
        position: sticky;
        top: 65px;
        z-index: 100;
        box-shadow: 0 0 35px 0 rgba(154,161,171,.15);

        @include media-breakpoint-down(md) { 
            position: relative;
            top: inherit;
        }

        &.card {
            padding-right: 0;
        }
    }

    .week-number {
        width: 310px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .75px;
        font-size: 12px;
        color: $gray-900;

        @include media-breakpoint-down(md) { 
            width: 128px;
        }
    }

    .week-times {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: -15px;
        
        .micro-time {
            font-size: 9px;
            font-weight: 600;
            width: 3.33333333%;

            @include media-breakpoint-down(lg) { 
                transform: rotate(-45deg);
            }

            @media (max-width: 900px) {
                display: none;
            }
        }
    }

    .day-row {
        display: flex;
        margin: 10px 0;
        position: relative;

        &:last-of-type {
            .date {
                &::after {
                    border-bottom: none;
                }
            }

            .room {
                &::after {
                    border-bottom: none;
                }
            }
        }

        .date {
            width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            color: $gray-900;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: -5px;
                border-bottom: 1px solid #F1F1F1;
            }

            &.today {
                color: $primary;
            }

            .day-number {
                font-size: 30px;
                font-weight: 600;
                margin-left: -10px;
            }

            .day-name {
                text-transform: uppercase;
                letter-spacing: .75px;
                font-weight: 700;
                margin-left: -10px;
            }
        }

        .room {
            width: 180px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;
        

            @include media-breakpoint-down(md) {
                display: none;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: -5px;
                border-bottom: 1px solid #F1F1F1;
            }

            .room-row {
                font-size: 11px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: .25px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                position: relative;
                height: 30px;

                &.dve-zony {
                    height: 60px;
                }

                &.tri-zony {
                    height: 90px;
                }

                &.styri-zony {
                    height: 120px;
                }
            }
        }

        .day-timetable {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;

            &.card {
                padding: 0;
            }
        }

        .timetable-row {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            position: relative;
            border-bottom: 1px solid #f1f1f1;

            &:last-of-type {
                border-bottom: none;
            }
        }

        .desktop-timetable {
            @media (max-width: 900px) {
                display: none;
            }
        }

        .mobile-timetable {
            display: flex;
            flex-direction: column;
            margin: 5px 5px 0 5px;

            @media (min-width: 900px) {
                display: none;
            }
        }

        .pill {
            position: absolute;
            top: 0;
            height: 100%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            box-sizing: border-box;
            font-weight: 600;
            letter-spacing: .5px;
            text-transform: uppercase;
            font-size: 10px;
            cursor: pointer;

            &.selected {
                color: $gray-900;
                background-color: white;
                border: 2px solid $gray-900;
            }

            i {
                font-size: 12px;
                margin-left: 4px;
            }
        }

        .pill-mobile {
            color: white;
            border-radius: 13px;
            display: flex;
            padding: 5px 10px;
            margin-bottom: 5px;
            font-weight: 600;
            letter-spacing: .5px;
            text-transform: uppercase;
            font-size: 11px;
            cursor: pointer;

            i {
                font-size: 12px;
                margin-right: 3px;
                margin-top: 2px;
            }

            span {
                text-transform: none;
                font-weight: 400;
            }
        }
        
    }
}

.card-outline {
    padding: 15px;
    border: 1px solid #f1eeee;
    border-radius: 7px;
    margin-bottom: 15px;
    text-align: right;
    box-shadow: 0 0.75rem 6rem rgba(56,65,74,.03);

    .card-outline-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        color: $gray-900;
        font-family: "Cerebri Sans", sans-serif;
        font-weight: 400;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .label {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        letter-spacing: .25px;
        margin-right: 5px;
    }

    .highligh {
        color: $primary;
        font-weight: 600;
    }
}

.user-box {
    padding: 15px 20px;
    border: 1px solid #f1eeee;
    border-radius: 6px;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74,.03);
    overflow: hidden;
    position: relative;

    img {
        height: 32px;
        width: 32px;
    }
}

///////////  KURZY  ///////////

.ls-1 {
    letter-spacing: .25px;
}

.ls-2 {
    letter-spacing: .5px;
}

.ls-3 {
    letter-spacing: .75px;
}

#kurzy {
    .card {
        transition: all .25s;
        cursor: pointer;
        margin-bottom: 40px;
        overflow: visible;

        &:hover {
            transform: scale(1.025);
            box-shadow: 0 0 45px 0 rgba(154,161,171,.25);
            transition: all .25s;

            .img-fluid {
                opacity: .90;
                transition: all .25s;
            }
        }

        &.ribbon-box {
            .ribbon-two {
                right: -5px;
                left: auto;
                display: none;
            }

            &.kupeny-kurz {
                .ribbon-two {
                    display: block;
                }
            }
        }

        .ribbon-two-light {
            span {
                background: white;
                top: 14px;
                right: -23px;
                left: auto;
                font-size: 12px;
                line-height: 26px;
                font-family: "Cerebri Sans,sans-serif";
                letter-spacing: .25px;
                text-transform: uppercase;
                font-weight: 700;
                color: $gray-900;
                transform: rotate(45deg);
            }
        }

        .img-fluid {
            max-height: 150px;
            object-fit: cover;
            transition: all .25s;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .card-title {
            line-height: 1.3 !important;
        }

        .text-muted {
            font-weight: 600;

            i {
                margin-top: 3px;
                margin-right: 8px;
            }
        }

        h6 {
            &.card-header {
                text-transform: uppercase;
                letter-spacing: .25px;
                color: $gray-600;
                background-color: white;
                padding-top: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

#kurz-detail {
    .ribbon-box {
        .ribbon {
            font-size: 15px;
            margin-left: 5px;
            margin-bottom: 25px;
            white-space: nowrap;
            letter-spacing: .25px;
            border-radius: 6px;
            border-bottom-right-radius: 0;

            @include media-breakpoint-down(xs) { 
                margin-left: 100%;
                margin-top: -10px;
            }
        }

        h2 {
            letter-spacing: .5px;
            margin: 2px 0 35px 0;
        }

        .sub-heading {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            .fe-clock {
                margin-top: 3px;
            }
        }
    } 

    .detail-kurzu-image {
        border-radius: 8px;
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: 315px;
    }
}

#prihlasenie-na-kurz {
    overflow: hidden;

    h4 {
        margin: 25px 0 25px 0;
    }

    .prihlasenie_dieta {
        display: none;
    }
    
    hr {
        margin: 20px -30px;
        border-top-color: #f1eeee;
    }
    .card-outline {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .highlight {
            font-size: 16px;
            color: $gray-900;
            font-weight: 600;
        }
    }

    #prihlasenie_kurz_accordion {
        max-width: 955px;

        .card {
            border: none;
            box-shadow: $shadow-sm;
    
            .card-header {
                background-color: white;
            }
        }

        #dni_volna {
            max-height: 186px;
            overflow: hidden;
        }

        #dni_volna_datumy {
            max-height: 125px;
            overflow: hidden;
        }

        #dni_volna_zobrazit {
            display: none;
            text-align: center;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
            position: absolute;
            bottom: 12px;
            width: 100%;
            padding-top: 25px;
        }
    }

    #kontakte_info_edit, #kontakte_info_zastupca_edit, #firemne_udaje, #fakturacne_info_edit, #firemne_udaje_edit {
        display: none;
    }
    
    #kurz-zhrnutie {
        .label-heading-xs {
            margin-bottom: 15px;
        }
    }

    #zlavovy_kupon {
        display: none;

        #zlavovy_kupon_hodnota {
            display: none;
        }

        .btn-light {
            border: 1px solid #ced4da;
        }
    }
}

#vyber_dni_kurz {
    &.fixed {
        .option {
            pointer-events: none;
        }

        .checkmark {
            display: none;
        }
    }

    hr {
        margin-top: 13px;
        margin-bottom: 13px;
    }

    .day {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .day-label {
        width: 100%;
        font-family: "Cerebri Sans,sans-serif";
        text-transform: uppercase;
        letter-spacing: .5px;
        color: $gray-900;
        font-weight: 500;
        margin-bottom: 3px;

        @include media-breakpoint-down(sm) { 
            width: 110px;
        }
    }

    .option {
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border-radius: 50px;
        background-color: white;
        margin-bottom: 0px;
        width: 100%;
        height: 32px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            .mdi {
                color: $success;
                opacity: .5;
            }

            .mdi.checked {
                opacity: 1;
            }
        }

        .mdi {
            font-size: 21px;
            margin: 0 10px 0 5px;
        }

        .checked {
            display: none;
        }

        .unchecked {
            color: #f1eeee;
        }
        
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   

            &:checked ~ .checkmark .unchecked {
                display: none;
            }

            &:checked ~ .checkmark .checked {
                display: block;
            }

            &:disabled ~ .checkmark, &:disabled ~ .checkmark i, &:disabled ~ .time, &:disabled ~ .availability, &:disabled ~ .location {
                opacity: .5;
                color: $gray-600;
                cursor: not-allowed;
            }

            &:disabled ~ .checkmark i {
                color: #f1eeee;
            }
        }

        .time {
            width: 120px;
            color: $gray-900;
            font-weight: 600;
            white-space: nowrap;
        }

        .availability {
            width: 150px;
            white-space: nowrap;
            color: #98a6ad;

            .long {
                @include media-breakpoint-down(sm) { 
                    display: none;
                }
            }

            .short {
                display: none;

                @include media-breakpoint-down(sm) { 
                    display: block;
                }
            }

        }

        .location {
            white-space: nowrap;
            color: #98a6ad;

            @include media-breakpoint-down(sm) { 
                display: none;
            }
        }
    }  
}

#prihlasenie-na-kurz-uspesne {
    i {
        font-size: 18px;
        color: $gray-900;
    }
}

///////////  MOJE TRENINGY  ///////////

.single-line-card {
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
}

#moje-treningy, #moje-kurzy {
    .single-line-card {
        .row {
            overflow: scroll;
            
            .col-sm-12 {
                display: flex;
                align-items: center;

                &:last-of-type {
                    justify-content: flex-end;

                    @include media-breakpoint-down(sm) { 
                        margin-top: 10px;
                        margin-bottom: 10px;
                        justify-content: flex-start;
                    }
                }

                &:first-of-type {
                    font-weight: 600;
                    color: $gray-900;

                    @include media-breakpoint-down(sm) { 
                        margin-bottom: 10px;
                    }
                }
            }
        }

        i {
            margin-right: 7px;
        }

        .btn {
            font-size: 12px;
            padding-top: 3px;
            padding-bottom: 3px;
            cursor: pointer;
        }

        span {
            @include media-breakpoint-down(sm) {
                width: 100%;
                display: block;
            }
        }

        .d-block {
            .fe-clock {
                margin-top: 3px;
            }
        }

        a {
            font-size: 12px;
        }
    }

    .table-responsive {
        td {
            white-space: nowrap;
        }

        .btn {
            font-size: 12px;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
}

///////////  KREDITY  ///////////

#nakup_kreditov_accordion {
    max-width: 850px;

    .card {
        border: none;
        border-radius: 6px;
        box-shadow: $shadow-sm;

        .card-header {
            background-color: white;
        }
    }

    .package-box {
        padding: 15px 25px;
        background-color: $gray-200;
        border-radius: 5px;
    }

    #firemne_udaje, #fakturacne_info_edit, #firemne_udaje_edit {
        display: none;
    }
}

///////////  KONTAKT  ///////////

#kontakt {
    .card {
        overflow: hidden;
    }
    #map {
        height: 400px;
    }

   .social {
       i {
           font-size: 25px;
           margin-right: 15px;
       }
   }

   hr {
       margin: 20px -25px;
   }
}

///////////  FOOTER  ///////////

footer {
    i {
        font-size: 20px;
        margin-right: 10px;
    }
}

///////////  SWAL2 ZMENA ANIMACIE  ///////////

@keyframes swal2-show {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
}

@keyframes swal2-hide {
    from {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    to {
      opacity: 0;
    }
}